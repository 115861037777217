var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"min-width":"280px"}},[_c('div',{staticClass:"title"},[_vm._v("课程类型")]),_c('div',{staticClass:"course-type"},[_c('ul',_vm._l((_vm.types),function(item){return _c('li',{key:item.id,class:{
            active: item.id === _vm.params.id
          },on:{"click":function($event){return _vm.handlerCilck(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])]),_c('div',{staticClass:"course-list"},[_c('div',{staticClass:"title"},[_vm._v(" 课程列表 "),_c('span',{staticStyle:{"float":"right"}},[_c('el-form',{attrs:{"model":_vm.params,"inline":"","size":"small"}},[_c('el-form-item',[_c('el-select',{attrs:{"placeholder":"类型筛选"},model:{value:(_vm.params.courseType),callback:function ($$v) {_vm.$set(_vm.params, "courseType", $$v)},expression:"params.courseType"}},_vm._l((_vm.typeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('el-select',{attrs:{"placeholder":"状态筛选"},model:{value:(_vm.params.shelfStatus),callback:function ($$v) {_vm.$set(_vm.params, "shelfStatus", $$v)},expression:"params.shelfStatus"}},[(this.params.id == 40 || this.params.id == 42)?_vm._l((_vm.freeStatusOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}):_vm._e(),(this.params.id == 41)?_vm._l((_vm.tollStatusOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}):_vm._e()],2)],1),_c('el-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('el-input',{attrs:{"placeholder":"请输入关键字","size":"small","clearable":""},model:{value:(_vm.params.keyword),callback:function ($$v) {_vm.$set(_vm.params, "keyword", $$v)},expression:"params.keyword"}})],1),_c('el-button',{attrs:{"icon":"el-icon-search","size":"small","type":"primary","plain":""},on:{"click":_vm.search}},[_vm._v(" 搜索 ")])],1)],1)]),_c('div',{staticClass:"course-list-card"},[_c('div',{staticClass:"table-container"},[_c('el-table',{key:Math.random(),staticStyle:{"width":"100%"},attrs:{"header-align":"center","data":_vm.tableData,"header-cell-style":{
            background: '#E9F3FE',
            fontWeight: 'blod'
          }}},[_c('el-table-column',{attrs:{"label":"编号","width":"55","type":"index","align":"center"}}),_c('el-table-column',{attrs:{"prop":"liveImg","label":"课程封面","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticStyle:{"height":"80px"},attrs:{"src":scope.row.liveImg,"alt":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"liveTitle","label":"课程信息","align":"center"}}),(_vm.params.id !== 40)?_c('el-table-column',{attrs:{"prop":"price","label":"价格信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" 原价："+_vm._s(scope.row.price)+" "),_c('br'),_vm._v(" 现价："+_vm._s(scope.row.discountPrice)+" "),_c('br'),_vm._v(" 有效期："+_vm._s(_vm.getDeadLine(scope.row.deadLine))),_c('br'),_vm._v(" 销量："+_vm._s(scope.row.sales)),_c('br')]}}],null,false,459784616)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"liveBeginTime","label":"课程时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatTime")(scope.row.liveBeginTime,"YYYY-MM-DD HH:mm"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"livePlayPv","label":"点击量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"chapterNum","label":"集数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"liveStatus","label":"课程类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.courseStatusMap(scope.row.liveStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"shelfStatus","label":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"type"},[_c('div',{staticClass:"type-content"},[_c('div',{staticClass:"type-circle",style:({
                      background:
                        scope.row.shelfStatus == 1 ? '#3CD384' : '#C25454 '
                    })}),_c('span',[_vm._v(" "+_vm._s(_vm.shelfStatusMap(scope.row.shelfStatus))+" ")])])])]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.preview(scope.row)}}},[_vm._v("查看")]),_c('el-button',{key:scope.row.id + '-' + scope.row.shelfStatus,style:({
                  color: scope.row.shelfStatus == 2 ? '#3CD384' : '#2A8BEE'
                }),attrs:{"type":"text"},on:{"click":function($event){return _vm.shelfSwitch(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.shelfStatus == 2 ? "上架" : "下架")+" ")]),(_vm.params.id !== 41)?_c('el-button',{staticStyle:{"color":"#f22727"},attrs:{"type":"text"},on:{"click":function($event){return _vm.del(scope.row.id)}}},[_vm._v("删除")]):_vm._e()]}}])})],1),_c('Pagination',{attrs:{"total":_vm.total,"page":_vm.params.current,"limit":_vm.params.size},on:{"update:page":function($event){return _vm.$set(_vm.params, "current", $event)},"update:limit":function($event){return _vm.$set(_vm.params, "size", $event)},"pagination":function($event){return _vm.getData()}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
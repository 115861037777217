import { render, staticRenderFns } from "./QuickEntrance.vue?vue&type=template&id=284f010d&scoped=true"
import script from "./QuickEntrance.vue?vue&type=script&lang=js"
export * from "./QuickEntrance.vue?vue&type=script&lang=js"
import style0 from "./QuickEntrance.vue?vue&type=style&index=0&id=284f010d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284f010d",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview"},[_c('div',{staticClass:"overview-content color1"},[_vm._m(0),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" "+_vm._s(_vm.data.accountNum)+" ")]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 个 ")])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 员工账号总数 ")])])]),_c('div',{staticClass:"overview-content color2"},[_vm._m(1),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" "+_vm._s(_vm.data.dayStudyEmployeeNum)+" ")]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 人 ")])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 今日学习员工 ")])])]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"overview-content color3"},[_vm._m(4),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" "+_vm._s(_vm.data.avgStudyDurationStr)+" ")]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 分 ")])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 人均 ")])])]),_c('div',{staticClass:"overview-content color5"},[_vm._m(5),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" "+_vm._s(_vm.data.dayStudyDurationStr)+" ")]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 分 ")])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 今日学习时长 ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content color3"},[_c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-03.png"),"alt":""}})]),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" -- ")]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 个 ")])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 已购企业课程 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content color4"},[_c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-04.png"),"alt":""}})]),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" -- ")]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 个 ")])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 已购直播课程 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-05.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-06.png"),"alt":""}})])
}]

export { render, staticRenderFns }
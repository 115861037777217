var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview"},[_c('div',{staticClass:"overview-content",on:{"click":function($event){return _vm.goSeriesCourse()}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"overview-content",on:{"click":function($event){return _vm.goBroadcastCourse()}}},[_vm._m(2),_vm._m(3)]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/创建系列课.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" 创建系列课 ")]),_c('div',{staticClass:"overview-content-right-item"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/箭头.png"),"alt":""}})])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 前往创建机构系列课程 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/创建录播课.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" 创建录播课 ")]),_c('div',{staticClass:"overview-content-right-item"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/箭头.png"),"alt":""}})])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 前往创建机构录播课程 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content"},[_c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/敬请期待2.png"),"alt":""}})]),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" 敬请期待 ")]),_c('div',{staticClass:"overview-content-right-item"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/箭头.png"),"alt":""}})])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 快捷入口新功能 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overview-content"},[_c('div',{staticClass:"overview-content-icon"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/敬请期待1.png"),"alt":""}})]),_c('div',{staticClass:"overview-content-right"},[_c('div',{staticClass:"overview-content-header"},[_c('div',{staticClass:"overview-content-right-data"},[_vm._v(" 敬请期待 ")]),_c('div',{staticClass:"overview-content-right-item"},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/icon/箭头.png"),"alt":""}})])]),_c('div',{staticClass:"overview-content-right-item"},[_vm._v(" 快捷入口新功能 ")])])])
}]

export { render, staticRenderFns }